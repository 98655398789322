import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { MappingModel } from 'src/app/models/mapping_model';
import { UserModel } from 'src/app/models/user_model';
import { BackendService } from 'src/app/services/backend.service';
import { LocalService } from 'src/app/services/local.service';
import { SocketService } from 'src/app/services/socket.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserApi {

  constructor(
    private localService: LocalService,
    private backendService: BackendService,
    private socketService: SocketService,
  ) {
  }

  public keepLocalUserUpdated() {
    const userId = this.localService.getLocalUserId();
    this.getUserObservable(userId).subscribe((user: UserModel) => {
      this.localService.setLocalUser(user);
    })
  }

  public getUserObservable(userId: string): Observable<UserModel> {
    const endpoint = `/users/${userId}`;
    const observedEndpoint = this.socketService.observableOf(endpoint);
    return observedEndpoint.pipe(flatMap(async (trigger: boolean) => {
      const data = await this.backendService.get(endpoint).toPromise();
      const user = UserModel.parse(data);
      this.userCache[userId] = user;
      return user;
    }))
  }

  private userCache: {[userId: string]: UserModel} = {};

  public async getCachedUser(userId: string): Promise<UserModel> {
    if(!this.userCache[userId]) {
      const data = await this.backendService.get(`/users/${userId}`).toPromise();
      const user = UserModel.parse(data);
      this.userCache[userId] = user;
    }
    return this.userCache[userId];
  }

  public async searchUsers(search: string): Promise<UserModel[]> {
    const data = await this.backendService.post(`/users/search`, {search: search}).toPromise();
    const results: UserModel[] = data.map(user => UserModel.parse(user));
    return results;
  }

  public async getUserMappings(userId: string): Promise<MappingModel[]> {
    const data = await this.backendService.get(`/users/${userId}/mappings`).toPromise();
    const results: MappingModel[] = data.map(user => MappingModel.parse(user));
    // Sort reverse alphabetical
    results.sort((a, b) => -1 * a.deviceExternalId.localeCompare(b.deviceExternalId))
    return results;
  }

  public async updateFcmToken() {
    const swRegistration = await navigator.serviceWorker.getRegistration()
    const token = await environment.fmessaging.getToken(
      environment.fmessaging.getMessaging(),
      {
        vapidKey: environment.fcmVapidKey,
        serviceWorkerRegistration: swRegistration,
      }
    );
    const userId = this.localService.getLocalUserId();
    await this.backendService.post(`/users/${userId}/fcm`, {token: token}).toPromise();
  }

  public async sendNotificationBroadcast(title: string, message: string, url: string) {
    const data = {
      title: title,
      message: message,
      url: url,
    }
    const userId = this.localService.getLocalUserId();
    return await this.backendService.post(`/platform/fcm/broadcast`, data).toPromise();
  }

  public async getPlatformAnalytics(): Promise<{companies: number, users: number, devices: number}> {
    const userId = this.localService.getLocalUserId();
    return await this.backendService.get(`/platform/analytics`).toPromise();
  }

  public getAllUsersObservable(): Observable<UserModel[]> {
    const endpoint = `/platform/users`;
    const observedEndpoint = this.socketService.observableOf(endpoint);
    return observedEndpoint.pipe(flatMap(async (trigger: boolean) => {
      const data = await this.backendService.get(endpoint).toPromise();
      const results: UserModel[] = data.map(user => UserModel.parse(user));
      return results;
    }))
  }

  public async getCompanyUsers(companyExternalId: string): Promise<UserModel[]> {
    const data = await this.backendService.get(`/companies/${companyExternalId}/users`).toPromise();
    const results: UserModel[] = data.map(user => UserModel.parse(user));
    return results;
  }

  public getCompanyUsersObservable(companyExternalId: string): Observable<UserModel[]> {
    const endpoint = `/companies/${companyExternalId}/users`;
    const observedEndpoint = this.socketService.observableOf(endpoint);
    return observedEndpoint.pipe(flatMap(async (trigger: boolean) => {
      const data = await this.backendService.get(endpoint).toPromise();
      const results: UserModel[] = data.map(user => UserModel.parse(user));
      return results;
    }))
  }

  public async setUserCredential(userId: string, value: string) {
    const resp = await this.backendService.post(`/users/${userId}/credential`, { value: value }).toPromise();
    this.socketService.pingHook(`/platform/users`);
    return resp;
  }
}
