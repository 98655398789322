import * as formatter from 'src/app/utils/formatter';
import { UserOrgs } from '../utils/constants';

/**
 * Server model
 */
export class UserModel {

   constructor(
    public id: string,
    public externalId: string,
    public name: string,
    public phone: string,
    public login: string,
    public photoUrl: string,
    public googleId: string,
    public org: UserOrgs,
    public companyExternalId: string,
    public cgwaOnly: boolean,
    public dailyConsumptionCycle: string,
    public createdAt: Date,
    public updatedAt: Date
  ) {}

  public static parse(item: any): UserModel {
    return new UserModel(
      item._id || null,
      item.externalId || null,
      item.name || null,
      item.phone || null,
      item.login || null,
      item.photoUrl || '/assets/person.jpg',
      item.googleId || null,
      item.org || null,
      item.companyExternalId || null,
      item.cgwaOnly || false,
      item.dailyConsumptionCycle || '0',
      new Date(item.createdAt) || null,
      new Date(item.updatedAt) || null,
    );
  }
}

/**
 * Wrapper around server model, contains helper functions for View
 */
export class UserViewModel extends UserModel {
  /**
   * These are view model properties, used to decide what to show in UI
   * to differentiate them from business model properties
   * we are using setters to set their value.
   */
  constructor(
    user: UserModel,
    private viewedById: string,
    private viewedByOrg: UserOrgs) {
      super(
        user.id,
        user.externalId,
        user.name,
        user.phone,
        user.login,
        user.photoUrl,
        user.googleId,
        user.org,
        user.companyExternalId,
        user.cgwaOnly,
        user.dailyConsumptionCycle,
        user.createdAt,
        user.updatedAt,
      )
  }

  isEditable() {
    switch(this.viewedByOrg) {
      case UserOrgs.kritsnam: return true;
      case UserOrgs.client: return this.id === this.viewedById;
      default: return this.id === this.viewedById;
    }
  }

  isPasswordLoginEnabled() {
    return this.login !== null && this.login !== '';
  }

  /** Shortcuts to access formatter in HTML Template */
  getShortId = () => formatter.getShortId(this);
}
