<div id="user-alerts-page" fxFlex="1 1 auto" fxLayout="column">
  <mat-progress-bar *ngIf="inProgressCounter !== 0" mode="indeterminate" color="accent"></mat-progress-bar>

  <app-loading-view *ngIf="uiState == ViewUiState.loading" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
  </app-loading-view>

  <app-error-view *ngIf="uiState == ViewUiState.error" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}"
  [errorText]="errorText" (resetClick)="onClickReset()">
  </app-error-view>

  <div fxLayout="row" fxLayoutAlign="center" *ngIf="uiState == ViewUiState.data"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
    <mat-card fxFlex="70%" fxFlex.xl ="70%" fxFlex.lg ="85%" fxFlex.md="90%" fxFlex.sm="90%" fxFlex.xs="100%"
    [ngStyle.xs]="{'margin.px': 0, 'padding.px': 0}"
    [ngStyle.gt-xs]="{'margin.px': 8, 'padding.px': 0}">

      <div fxLayout="row wrap">
        <h2 [ngStyle.xs]="{'padding.px': 8, 'margin.px': 0}" [ngStyle.gt-xs]="{'padding.px': 16, 'margin.px': 0}">
          <span>Alerts</span>
        </h2>
        <span class="spacer"></span>
        <div>
          <mat-form-field appearance="standard" style="padding: 8px; width: 150px; padding-right: 16px; margin-bottom: -1.25em;">
            <mat-label>Device</mat-label>
            <mat-select [value]="selectedMapping" (valueChange)="onClickMappingChange($event)">
              <mat-option *ngFor="let mapping of mappings; index as i" [value]="mapping">
                {{ mapping.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-error fxLayout="row" fxLayoutAlign="start center" *ngIf="bannerText" class="error-container" style="border-radius: 4px; padding: 0px 16px 0px 16px;">
        <span>{{ bannerText }}</span>
        <span class="spacer"></span>
        <button mat-icon-button color="warn" (click)="bannerText = null"><mat-icon>close</mat-icon></button>
      </mat-error>

      <mat-accordion multi
      [ngStyle.xs]="{'padding-top.px': 0, 'padding-right.px': 4, 'padding-bottom.px': 4, 'padding-left.px': 4}"
      [ngStyle.gt-xs]="{'padding-top.px': 0, 'padding-right.px': 0, 'padding-bottom.px': 16, 'padding-left.px': 0}">
        <div *ngIf="data.alerts.length === 0" style="padding: 16px;">No alerts found. Click the plus button to create an alert.</div>
        <mat-expansion-panel *ngFor="let alert of data.alerts">
          <mat-expansion-panel-header style="padding-left: 16px"
          [ngStyle.xs]="{'padding-top.px': 16, 'padding-bottom.px': 16}"
          [ngStyle.gt-xs]="{'padding-top.px': 8, 'padding-bottom.px': 8}">
            <mat-panel-title fxFlex>{{ alert.alertCondition }}</mat-panel-title>
            <div *ngIf="mobileView" style="padding-right: 16px" (click)="stopPropagation($event)">
              <button mat-icon-button (click)="onClickEditAlert(alert)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="onClickDeleteAlert(alert)">
                <mat-icon>delete</mat-icon>
              </button>
              <button mat-icon-button (click)="onClickTestAlert(alert)">
                <mat-icon>send</mat-icon>
              </button>
            </div>
            <div *ngIf="!mobileView" style="padding-right: 16px" (click)="stopPropagation($event)">
              <button mat-flat-button (click)="onClickEditAlert(alert)">
                <mat-icon style="padding-right: 4px">edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-flat-button (click)="onClickDeleteAlert(alert)">
                <mat-icon style="padding-right: 4px">delete</mat-icon>
                <span>Delete</span>
              </button>
              <button mat-flat-button (click)="onClickTestAlert(alert)">
                <mat-icon style="padding-right: 4px">send</mat-icon>
                <span>Test</span>
              </button>
            </div>
          </mat-expansion-panel-header>
          <mat-card-content>
            <responsive-row-view>
              <div class="key">ID</div>
              <div class="value">{{alert.getShortId()}}</div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Priority</div>
              <div class="value">{{alert.priority}}</div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Target Device</div>
              <div class="value">{{alert.deviceExternalId}}</div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Target Reading</div>
              <div class="value">{{alert.target}}</div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Target Period</div>
              <div class="value">{{alert.period}}</div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Threshold Value</div>
              <div class="value">{{alert.numberWithCommas(alert.threshold)}}</div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Send Email at</div>
              <div class="value">
                <mat-chip-list>
                  <mat-chip *ngFor="let email of alert.emails">{{ email }}</mat-chip>
                </mat-chip-list>
              </div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Send SMS at</div>
              <div class="value">
                <mat-chip-list>
                  <mat-chip *ngFor="let phone of alert.phones">{{ formatPhone(phone) }}</mat-chip>
                </mat-chip-list>
              </div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Send Push Notification to</div>
              <div class="value">
                <mat-chip-list>
                  <mat-chip *ngFor="let fcm of alert.fcms">{{ formatFcm(fcm) }}</mat-chip>
                </mat-chip-list>
              </div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Description</div>
              <div class="value">{{alert.formatAlertDescriptionMessage()}}</div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Created By</div>
              <div class="value">{{formatFcm(alert.createdBy)}}</div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Created At</div>
              <div class="value">{{alert.createdAt | date: 'EEEE, d MMM y'}}</div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Modified By</div>
              <div *ngIf="alert.modifiedBy" class="value">{{formatFcm(alert.modifiedBy) || '-'}}</div>
              <div *ngIf="!alert.modifiedBy" class="value">-</div>
            </responsive-row-view>
            <responsive-row-view>
              <div class="key">Modified At</div>
              <div *ngIf="alert.modifiedAt" class="value">{{alert.modifiedAt | date: 'EEEE, d MMM y'}}</div>
              <div *ngIf="!alert.modifiedAt" class="value">-</div>
            </responsive-row-view>
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </div>

  <div *ngIf="uiState == ViewUiState.data" class="app-fab-button" [class.poof]="scrollingDown">
    <button mat-fab color="primary" (click)="onClickFab()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
