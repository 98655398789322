<div fxFlex="1 1 auto" fxLayout="column">
    <app-loading-view *ngIf="uiState == ViewUiState.loading" fxFlex="1 1 auto" [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
    </app-loading-view>

    <app-error-view *ngIf="uiState == ViewUiState.error" fxFlex="1 1 auto" [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}" [errorText]="errorText" (resetClick)="onClickReset()">
    </app-error-view>

    <div fxLayout="row" fxLayoutAlign="center" *ngIf="uiState == ViewUiState.data" [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
        <mat-card fxFlex="70%" fxFlex.xl="70%" fxFlex.lg="85%" fxFlex.md="90%" fxFlex.sm="90%" fxFlex.xs="100%" [ngStyle.xs]="{'margin.px': 0, 'padding.px': 0}" [ngStyle.gt-xs]="{'margin.px': 8, 'padding.px': 0}">

            <div fxLayout="row wrap">
                <h2 [ngStyle.xs]="{'padding.px': 8, 'margin.px': 0}" [ngStyle.gt-xs]="{'padding.px': 16, 'margin.px': 0}">
                    <span>Monthly Report</span>
                </h2>
                <span class="spacer"></span>
                <div fxLayout="row" fxLayoutAlign="space-around center">
                    <button *ngIf="!mobileView" mat-button (click)="onClickPrint()">
            <mat-icon style="padding: 8px;">print</mat-icon>
            <span style="padding-right: 14px;">Print</span>
          </button>
                    <mat-form-field appearance="standard" style="padding: 8px; width: 150px; margin-bottom: -1.25em">
                        <mat-label>Device</mat-label>
                        <mat-select [value]="selectedMapping" (valueChange)="onClickMappingChange($event)">
                            <mat-option *ngFor="let mapping of mappings; index as i" [value]="mapping">
                                {{ mapping.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="standard" style="padding: 8px; width: 150px; padding-right: 16px; margin-bottom: -1.25em;">
                        <mat-label>Date</mat-label>
                        <input matInput [matDatepicker]="datePicker" [value]="selectedDate" [max]="today" (dateChange)="onClickDateChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                        <mat-datepicker #datePicker disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <mat-error fxLayout="row" fxLayoutAlign="start center" *ngIf="bannerText" class="error-container" style="border-radius: 4px; padding: 0px 16px 0px 16px;">
                <span>{{ bannerText }}</span>
                <span class="spacer"></span>
                <button mat-icon-button color="warn" (click)="bannerText = null"><mat-icon>close</mat-icon></button>
            </mat-error>

            <mat-card-content [ngStyle.xs]="{'padding-top.px': 0, 'padding-right.px': 4, 'padding-bottom.px': 4, 'padding-left.px': 4}" [ngStyle.gt-xs]="{'padding-top.px': 0, 'padding-right.px': 0, 'padding-bottom.px': 16, 'padding-left.px': 0}">
                <div fxLayout="row wrap" [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}" [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex="50%" fxFlex.lt-md="100%" [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row wrap">
                            <h4 style="margin: 0px; display: inline;">Monthly Cumulative Consumption</h4>
                        </div>
                        <hr>
                        <div fxLayout="row wrap">
                            <div fxLayout="column" fxFlex="30%" fxFlex>
                                <div style="padding: 0px 0px 8px 24px;">
                                    <h3 style="font-size: 14px; margin: 0px">Net Volume</h3>
                                    <h2>
                                        {{ month.net_volume * unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </h2>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="30%" fxFlex>
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Previous Net Volume</h3>
                                    <h2>
                                        {{ monthBefore.net_volume * unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </h2>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="30%" fxFlex *ngIf="selectedTarget.dailyNocLimit">
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Prorated NOC Limit</h3>
                                    <h2>
                                        {{ monthlyNocLimit * unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div class="ngx-chart-wrapper" style="padding: 16px 0px;" fxLayout="column">
                            <custom-ngx-charts-area-chart [results]="dailyChartCumulativeData" [scheme]="dailyChartColorScheme" [showGridLines]="true" [animations]="false" [gradient]="true" [yAxis]="true" [yAxisTicks]="[0, max(monthlyNocLimit, maxDailyCumulative)]" [yScaleMax]="max(monthlyNocLimit, maxDailyCumulative)"
                                [nocValue]="monthlyNocLimit" [yAxisTicks]="dailyChartYAxisTicks">
                            </custom-ngx-charts-area-chart>
                        </div>
                    </mat-card>
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex="50%" fxFlex.lt-md="100%" fxFlex fxLayout="column" [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row wrap">
                            <h4 style="margin: 0px; display: inline;">Month Extraction Details</h4>
                            <span class="spacer"></span>
                            <mat-card-subtitle style="margin: 0px; display: inline;">
                                {{ selectedDate.clone().startOf('month') | date: 'MMM y' }} to {{ selectedDate.clone().startOf('month').add(1, 'month') | date: 'MMM y' }}
                            </mat-card-subtitle>
                        </div>
                        <hr style="width: 100%;">
                        <div fxLayout="row wrap" fxFlex>
                            <div fxLayout="column" fxLayoutAlign="space-around" fxFlex="50%" fxFlex>
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Forward Flow</h3>
                                    <p style="font-size: 18px">
                                        {{ month.forward_flow * unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </p>
                                </div>
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Flow Rate</h3>
                                    <p style="font-size: 18px">
                                        {{ month.flowrate * unitDetails.volume / unitDetails.time | number: '1.2-2' }} {{ unitDetails.symbols.flowrate }}
                                    </p>
                                </div>
                                <div [style.visibility]="selectedTarget.showElectricityRate ? 'visible' : 'hidden'" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Electricity Bill</h3>
                                    <p style="font-size: 18px">
                                        {{ month.electricity_bill | currency:'INR':'₹ ':'1.0' }}
                                    </p>
                                </div>
                            </div>
                            <div fxLayout="column" fxLayoutAlign="space-around" fxFlex="50%" fxFlex>
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Working Time</h3>
                                    <p style="font-size: 18px">
                                        {{ month.getWorkHourString() }}
                                    </p>
                                </div>
                                <!-- <div style="padding: 0px 0px 8px 0px;">
                  <h3 style="font-size: 14px; margin: 0px">Reverse Flow</h3>
                  <p style="font-size: 18px">
                    {{ month.reverse_flow * unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                  </p>
                </div>-->
                                <div [style.visibility]="selectedTarget.showWaterRate ? 'visible' : 'hidden'" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Water Bill</h3>
                                    <p style="font-size: 18px">
                                        {{ month.water_bill | currency:'INR':'₹ ':'1.0' }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div fxLayout="row" [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}" [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row wrap">
                            <h4 style="margin: 0px; display: inline;">Monthly Day-wise Consumption</h4>
                        </div>
                        <hr>
                        <div fxLayout="row" fxLayoutAlign="space-around center">
                            <div *ngIf="!mobileView" class="ngx-chart-wrapper" style="padding: 16px 0px;" fxLayout="column" fxFlex="100%" fxLayoutAlign="start center">
                                <custom-ngx-charts-bar-vertical [scheme]="dailyChartColorScheme" [results]="dailyChartData" [showGridLines]="true" [xAxis]="true" [yAxis]="true" [rotateXAxisTicks]="false" barPadding=16 [referenceLines]="[{ value: selectedTarget.dailyNocLimit * unitDetails.volume, name: selectedTarget.dailyNocLimit*unitDetails.volume + ' ' + unitDetails.symbols.volume }]"
                                    [showRefLines]="!!selectedTarget.dailyNocLimit" [showRefLabels]="!!selectedTarget.dailyNocLimit" [roundEdges]="false">
                                </custom-ngx-charts-bar-vertical>
                            </div>
                            <div *ngIf="mobileView" class="ngx-chart-wrapper" style="padding: 16px 0px;" style="max-width: 80vw; overflow-x: scroll;">
                                <custom-ngx-charts-bar-vertical [view]="[1000, 250]" [scheme]="dailyChartColorScheme" [results]="dailyChartData" [showGridLines]="true" [xAxis]="true" [yAxis]="true" [rotateXAxisTicks]="false" barPadding=16 [referenceLines]="[{ value: selectedTarget.dailyNocLimit * unitDetails.volume, name: selectedTarget.dailyNocLimit + ' ' + unitDetails.symbols.volume }]"
                                    [showRefLines]="!!selectedTarget.dailyNocLimit" [showRefLabels]="!!selectedTarget.dailyNocLimit" [roundEdges]="false">
                                </custom-ngx-charts-bar-vertical>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div fxLayout="row" [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}" [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16, 'padding-top.px': 8}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16, 'padding-top.px': 8}">
                        <div fxLayout="row wrap" style="align-items: center;">
                            <h4 style="margin: 0px; display: inline;">Monthly Day-wise Data</h4>
                            <div class="spacer"></div>
                            <button mat-button (click)="onClickDownload()">
                Download
                <mat-icon>download</mat-icon>
              </button>
                        </div>
                        <hr>
                        <div fxLayout="row" fxLayoutAlign="space-around center">
                            <ag-grid-angular *ngIf="dailyTableData.length !== 0" id="grid" #agGrid style="width: 100%;" class="ag-theme-material" [rowData]="dailyTableData" [columnDefs]="columnDefs" [context]="agGridContext" [suppressCellSelection]="true" [pagination]="true" [paginationPageSize]="10"
                                [defaultColDef]="{
                resizable: true,
                sortable: true,
                filter: true
              }" domLayout='autoHeight' (gridReady)="gridReady($event)">
                            </ag-grid-angular>
                        </div>
                    </mat-card>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>