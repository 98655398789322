<div fxFlex="1 1 auto" fxLayout="column">
    <app-loading-view *ngIf="uiState == ViewUiState.loading" fxFlex="1 1 auto" [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
    </app-loading-view>

    <app-error-view *ngIf="uiState == ViewUiState.error" fxFlex="1 1 auto" [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}" [errorText]="errorText" (resetClick)="onClickReset()">
    </app-error-view>

    <div fxLayout="row" fxLayoutAlign="center" *ngIf="uiState == ViewUiState.data" [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
        <mat-card fxFlex="70%" fxFlex.xl="70%" fxFlex.lg="85%" fxFlex.md="90%" fxFlex.sm="90%" fxFlex.xs="100%" [ngStyle.xs]="{'margin.px': 0, 'padding.px': 0}" [ngStyle.gt-xs]="{'margin.px': 8, 'padding.px': 0}">

            <div fxLayout="row wrap">
                <h2 [ngStyle.xs]="{'padding.px': 8, 'margin.px': 0}" [ngStyle.gt-xs]="{'padding.px': 16, 'margin.px': 0}">
                    <span>Welcome, {{ company.name }}!</span>
                </h2>
                <span class="spacer"></span>
                <div fxLayout="row" fxLayoutAlign="space-around center">
                    <mat-form-field appearance="standard" style="padding: 8px; width: 150px; margin-bottom: -1.25em">
                        <mat-label>Device</mat-label>
                        <mat-select [value]="selectedMapping" (valueChange)="onClickMappingChange($event)">
                            <mat-option *ngFor="let mapping of mappings; index as i" [value]="mapping">
                                {{ mapping.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="standard" style="padding: 8px; width: 150px; padding-right: 16px; margin-bottom: -1.25em;">
                        <mat-label>Date</mat-label>
                        <input matInput [matDatepicker]="datePicker" [value]="selectedDate" [max]="today" (dateChange)="onClickDateChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                        <mat-datepicker #datePicker disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <mat-error fxLayout="row" fxLayoutAlign="start center" *ngIf="bannerText" class="error-container" style="border-radius: 4px; padding: 0px 16px 0px 16px;">
                <span>{{ bannerText }}</span>
                <span class="spacer"></span>
                <button mat-icon-button color="warn" (click)="bannerText = null"><mat-icon>close</mat-icon></button>
            </mat-error>

            <mat-card-content [ngStyle.xs]="{'padding-top.px': 0, 'padding-right.px': 4, 'padding-bottom.px': 4, 'padding-left.px': 4}" [ngStyle.gt-xs]="{'padding-top.px': 0, 'padding-right.px': 0, 'padding-bottom.px': 16, 'padding-left.px': 0}">
                <!--
                <div fxLayout="row wrap" [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}" [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex="30%" fxFlex.lt-md="100%" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row">
                            <h4 style="margin: 0px; display: inline;">{{selectedTarget.isGroup ? 'Group': 'Device'}} Details</h4>
                        </div>
                        <hr>
                        <div fxLayout="row wrap">
                            <div fxLayout="column" fxFlex="50%">
                                <div *ngIf="selectedTarget.name" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Name</h3>
                                    <p style="font-size: 18px;">
                                        {{ selectedTarget.name }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.isGroup" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Devices</h3>
                                    <p style="font-size: 18px; word-break: break-word;">
                                        {{ selectedTarget.aggregationFormula.split('+').join(', ') }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.lastCalibrationDate" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Last Calibration Date</h3>
                                    <p style="font-size: 18px;">
                                        {{ selectedTarget.lastCalibrationDate | date: 'd MMM y' }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.dailyNocLimit" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Daily NOC Limit</h3>
                                    <p style="font-size: 18px;">
                                        {{ selectedTarget.dailyNocLimit*unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.certificateFileName" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Calibration Certificate</h3>
                                    <button mat-button (click)="onClickDeviceCertificate()">
          <mat-icon style="padding: 8px;">file_present</mat-icon>
          <span style="padding-right: 14px;">Download</span>
        </button>
                                </div>
                                <div *ngIf="selectedTarget.signalPercentage" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Network Strength</h3>
                                    <div fxLayout="row wrap" fxLayoutAlign="start center" style="font-size: 18px;">
                                        <span *ngIf="selectedTarget.signalPercentage > 80" style="padding-left: 8px" style="padding-top: 4px">Full</span>
                                        <span *ngIf="selectedTarget.signalPercentage > 60 && selectedTarget.signalPercentage <= 80" style="padding-left: 8px" style="padding-top: 4px">High</span>
                                        <span *ngIf="selectedTarget.signalPercentage > 40 && selectedTarget.signalPercentage <= 60" style="padding-left: 8px" style="padding-top: 4px">Average</span>
                                        <span *ngIf="selectedTarget.signalPercentage <= 40" style="padding-left: 8px" style="padding-top: 4px">Low</span>
                                        <mat-icon *ngIf="selectedTarget.signalPercentage > 80" style="padding-left: 8px">signal_cellular_4_bar</mat-icon>
                                        <mat-icon *ngIf="selectedTarget.signalPercentage > 60 && selectedTarget.signalPercentage <= 80" style="padding-left: 8px">signal_cellular_3_bar</mat-icon>
                                        <mat-icon *ngIf="selectedTarget.signalPercentage > 40 && selectedTarget.signalPercentage <= 60" style="padding-left: 8px">signal_cellular_3_bar</mat-icon>
                                        <mat-icon *ngIf="selectedTarget.signalPercentage <= 40" style="padding-left: 8px">signal_cellular_3_bar</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="50%">
                                <div *ngIf="selectedTarget.externalId" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Serial Number</h3>
                                    <p style="font-size: 18px;">
                                        {{ selectedTarget.externalId }}
                                    </p>
                                </div>
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Monthly Report XLSX</h3>
                                    <button mat-button (click)="onClickDownloadMonthlyReport()">
          <mat-icon style="padding: 8px;">file_present</mat-icon>
          <span style="padding-right: 14px;">Download</span>
        </button>
                                </div>
                                <div *ngIf="lastcommunicationdate!=0" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Last Communication Date</h3>
                                    <p style="font-size: 18px;">
                                        {{ lastcommunicationdate | date: 'd MMM y' }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.batteryPercentage" style="padding: 0px 0px 16px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Battery Level</h3>
                                    <div fxLayout="row wrap" fxLayoutAlign="start center" style="font-size: 18px;">
                                        <span style="padding-top: 4px">{{ selectedTarget.batteryPercentage }}%</span>
                                        <mat-icon style="padding-left: 8px">battery_full</mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="selectedTarget.borewellID" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Borewell ID</h3>
                                    <p style="font-size: 18px;">
                                        {{ selectedTarget.borewellID }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex="30%" fxFlex.lt-md="100%" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row" style="cursor: pointer;" matRipple (click)="onClickDailyReport()">
                            <h4 style="margin: 0px; display: inline;">Consumption Of The Day</h4>
                            <span class="spacer"></span>
                            <mat-icon style="margin: 0px; padding-top: 4px; height: 18px; width: 18px; font-size: 18px">open_in_new</mat-icon>
                        </div>
                        <hr>
                        <mat-card-subtitle>
                            {{ daily.start_time | date: 'EEEE, d MMM y | h:mm a' }} to<br> {{ daily.end_time | date: 'EEEE, d MMM y | h:mm a' }}
                        </mat-card-subtitle>
                        <div fxLayout="row wrap">
                            <div fxLayout="column" fxFlex="50%">
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 16px; margin: 0px; padding-bottom: 4px;">Net Volume</h3>
                                    <p style="font-size: 26px">
                                        {{ daily.net_volume*unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.dailyNocLimit" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 16px; margin: 0px; padding-bottom: 4px;">Limit Utilization</h3>
                                    <p *ngIf="selectedTarget.dailyNocLimit > daily.net_volume" style="font-size: 18px">
                                        {{ (selectedTarget.dailyNocLimit - daily.net_volume) / selectedTarget.dailyNocLimit * 100 | number: '1.0-0' }}% underused
                                    </p>
                                    <p *ngIf="selectedTarget.dailyNocLimit <= daily.net_volume" style="font-size: 18px">
                                        {{ (daily.net_volume - selectedTarget.dailyNocLimit) / selectedTarget.dailyNocLimit * 100 | number: '1.0-0' }}% overused
                                    </p>
                                </div>
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Flow Rate</h3>
                                    <p style="font-size: 18px">
                                        {{ daily.flowrate*unitDetails.volume/unitDetails.time | number: '1.2-2' }} {{ unitDetails.symbols.flowrate }}
                                    </p>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="50%">
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 16px; margin: 0px; padding-bottom: 4px;">Working Time</h3>
                                    <p style="font-size: 26px">
                                        {{ daily.getWorkHourString() }}
                                    </p>
                                </div>
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Forward Flow</h3>
                                    <p style="font-size: 18px">
                                        {{ daily.forward_flow*unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </p>
                                </div>
                                <!--div style="padding: 0px 0px 8px 0px;">
                          <h3 style="font-size: 14px; margin: 0px">Reverse Flow</h3>
                          <p style="font-size: 18px">
                              {{ daily.reverse_flow*unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                          </p>
                      </div>
    </div>
</div>
</mat-card>-->
                <!--
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex="30%" fxFlex.lt-md="100%" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row" style="cursor: pointer;" matRipple (click)="onClickMonthlyReport()">
                            <h4 style="margin: 0px; display: inline;">Monthly Water Consumption</h4>
                            <span class="spacer"></span>
                            <mat-icon style="margin: 0px; padding-top: 4px; height: 18px; width: 18px; font-size: 18px">open_in_new</mat-icon>
                        </div>
                        <hr>
                        <mat-card-subtitle>
                            {{ selectedDate.clone() | date: 'MMM y' }} to {{ selectedDate.clone().add(1, 'month') | date: 'MMM y' }}
                        </mat-card-subtitle>
                        <div class="ngx-chart-wrapper" style="padding: 16px 0px;" fxLayout="column">
                            <custom-ngx-charts-bar-vertical [scheme]="weeklyChartColorScheme" [results]="weeklyChartData" [showGridLines]="true" [xAxis]="true" [yAxis]="true" [yAxisTicks]="yTicks" [rotateXAxisTicks]="false" barPadding=16 [referenceLines]="[{ value: selectedTarget.dailyNocLimit * 7 * unitDetails.volume, name: selectedTarget.dailyNocLimit * 7 * unitDetails.volume + ' ' + unitDetails.symbols.volume }]"
                                [showRefLines]="!!selectedTarget.dailyNocLimit" [showRefLabels]="!!selectedTarget.dailyNocLimit" [roundEdges]="false">
                            </custom-ngx-charts-bar-vertical>
                        </div>
        </mat-card>
    </div>-->
                <div fxLayout="row wrap" [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}" [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">

                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex="30%" fxFlex.lt-md="100%" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row">
                            <h4 style="margin: 0px; display: inline;">{{selectedTarget.isGroup ? 'Group': 'Device'}} Details</h4>
                        </div>
                        <hr>
                        <div fxLayout="row wrap">
                            <div fxLayout="column" fxFlex="50%">
                                <div *ngIf="selectedTarget.name" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Name</h3>
                                    <p style="font-size: 18px;">
                                        {{ selectedTarget.name }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.isGroup" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Devices</h3>
                                    <p style="font-size: 18px; word-break: break-word;">
                                        {{ selectedTarget.aggregationFormula.split('+').join(', ') }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.lastCalibrationDate" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Last Calibration Date</h3>
                                    <p style="font-size: 18px;">
                                        {{ selectedTarget.lastCalibrationDate | date: 'd MMM y' }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.dailyNocLimit" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Daily NOC Limit</h3>
                                    <p style="font-size: 18px;">
                                        {{ selectedTarget.dailyNocLimit*unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.certificateFileName" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Calibration Certificate</h3>
                                    <button mat-button (click)="onClickDeviceCertificate()">
  <mat-icon style="padding: 8px;">file_present</mat-icon>
  <span style="padding-right: 14px;">Download</span>
</button>
                                </div>
                                <div *ngIf="selectedTarget.signalPercentage" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Network Strength</h3>
                                    <div fxLayout="row wrap" fxLayoutAlign="start center" style="font-size: 18px;">
                                        <span *ngIf="selectedTarget.signalPercentage > 80" style="padding-left: 8px" style="padding-top: 4px">Full</span>
                                        <span *ngIf="selectedTarget.signalPercentage > 60 && selectedTarget.signalPercentage <= 80" style="padding-left: 8px" style="padding-top: 4px">High</span>
                                        <span *ngIf="selectedTarget.signalPercentage > 40 && selectedTarget.signalPercentage <= 60" style="padding-left: 8px" style="padding-top: 4px">Average</span>
                                        <span *ngIf="selectedTarget.signalPercentage <= 40" style="padding-left: 8px" style="padding-top: 4px">Low</span>
                                        <mat-icon *ngIf="selectedTarget.signalPercentage > 80" style="padding-left: 8px">signal_cellular_4_bar</mat-icon>
                                        <mat-icon *ngIf="selectedTarget.signalPercentage > 60 && selectedTarget.signalPercentage <= 80" style="padding-left: 8px">signal_cellular_3_bar</mat-icon>
                                        <mat-icon *ngIf="selectedTarget.signalPercentage > 40 && selectedTarget.signalPercentage <= 60" style="padding-left: 8px">signal_cellular_3_bar</mat-icon>
                                        <mat-icon *ngIf="selectedTarget.signalPercentage <= 40" style="padding-left: 8px">signal_cellular_3_bar</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="50%">
                                <div *ngIf="selectedTarget.externalId" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Serial Number</h3>
                                    <p style="font-size: 18px;">
                                        {{ selectedTarget.externalId }}
                                    </p>
                                </div>
                                <div style="padding: 0px 0px 8px 0px;" hidden>
                                    <h3 style="font-size: 14px; margin: 0px">Monthly Report XLSX</h3>
                                    <button mat-button (click)="onClickDownloadMonthlyReport()">
  <mat-icon style="padding: 8px;">file_present</mat-icon>
  <span style="padding-right: 14px;">Download</span>
</button>
                                </div>
                                <div *ngIf="lastcommunicationdate!=0" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Last Communication Date</h3>
                                    <p style="font-size: 18px;">
                                        {{ lastcommunicationdate | date: 'd MMM y' }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.batteryPercentage" style="padding: 0px 0px 16px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Battery Level</h3>
                                    <div fxLayout="row wrap" fxLayoutAlign="start center" style="font-size: 18px;">
                                        <span style="padding-top: 4px">{{ selectedTarget.batteryPercentage }}%</span>
                                        <mat-icon style="padding-left: 8px">battery_full</mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="selectedTarget.borewellID" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Borewell ID</h3>
                                    <p style="font-size: 18px;">
                                        {{ selectedTarget.borewellID }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div fxLayout="row" [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}" [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16, 'padding-top.px': 8}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16, 'padding-top.px': 8}">
                        <div fxLayout="row wrap" style="align-items: center;">
                            <h4 style="margin: 0px; display: inline;">Raw Data</h4>
                            <div class="spacer"></div>
                            <button mat-button (click)="onClickDownload()">
  Download
  <mat-icon>download</mat-icon>
</button>
                        </div>
                        <hr>
                        <div fxLayout="row" fxLayoutAlign="space-around center">
                            <ag-grid-angular *ngIf="rawTableData.length !== 0" id="grid" #agGrid style="width: 100%;" class="ag-theme-material" [rowData]="rawTableData" [columnDefs]="columnDefs" [context]="agGridContext" [suppressCellSelection]="true" [pagination]="true" [paginationPageSize]="10"
                                [defaultColDef]="{
  resizable: true,
  sortable: true,
  filter: true
}" domLayout='autoHeight' (gridReady)="gridReady($event)">
                            </ag-grid-angular>
                        </div>
                    </mat-card>
                </div>


                <!-- <div hidden fxLayout="row" [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}" [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row">
                            <h4 style="margin: 0px; display: inline;">Shift-wise Consumption</h4>
                        </div>
                        <hr>
                        <mat-card-subtitle>
                            {{ selectedDate.clone().subtract(1, 'day') | date: 'EEEE, d MMM y' }} to {{ selectedDate | date: 'EEEE, d MMM y' }}
                        </mat-card-subtitle>
                        <div *ngIf="dailyShifts && dailyShifts.length > 0" fxLayout="row wrap" fxLayoutAlign="space-around center">
                            <div *ngIf="dailyShifts.length > 0" fxLayout="column" fxFlex="33%" fxFlex.lt-md="90%">
                                <ngx-charts-number-card style="max-height: 150px; padding-left: 8px; pointer-events: none;" bandColor=#31C3DF cardColor=#232837 [results]="[dailyShifts[0]]" [valueFormatting]="shiftCardValueFormatting">
                                </ngx-charts-number-card>
                            </div>
                            <div *ngIf="dailyShifts.length > 1" fxLayout="column" fxFlex="33%" fxFlex.lt-md="90%">
                                <ngx-charts-number-card style="max-height: 150px; padding-left: 8px; pointer-events: none;" bandColor=#FFD47A cardColor=#232837 [results]="[dailyShifts[1]]" [valueFormatting]="shiftCardValueFormatting">
                                </ngx-charts-number-card>
                            </div>
                            <div *ngIf="dailyShifts.length > 2" fxLayout="column" fxFlex="33%" fxFlex.lt-md="90%">
                                <ngx-charts-number-card style="max-height: 150px; padding-left: 8px; pointer-events: none;" bandColor=#DC189C cardColor=#232837 [results]="[dailyShifts[2]]" [valueFormatting]="shiftCardValueFormatting">
                                </ngx-charts-number-card>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div hidden fxLayout="row" [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}" [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16, 'padding-top.px': 8}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16, 'padding-top.px': 8}">
                        <div fxLayout="row wrap" style="align-items: center;">
                            <h4 style="margin: 0px; display: inline;">Monthly Shift-wise Data</h4>
                            <div class="spacer"></div>
                            <button mat-button (click)="onClickDownload()">
                Download
                <mat-icon>download</mat-icon>
              </button>
                        </div>
                        <hr>
                        <div fxLayout="row" fxLayoutAlign="space-around center">
                            <ag-grid-angular *ngIf="shiftTableData.length !== 0" id="grid" #agGrid style="width: 100%;" class="ag-theme-material" [rowData]="shiftTableData" [columnDefs]="columnDefs" [context]="agGridContext" [suppressCellSelection]="true" [pagination]="true" [paginationPageSize]="10"
                                [defaultColDef]="{
                resizable: true,
                sortable: true,
                filter: true
              }" domLayout='autoHeight' (gridReady)="gridReady($event)">
                            </ag-grid-angular>
                        </div>
                    </mat-card>
                </div>-->
            </mat-card-content>
        </mat-card>
    </div>
</div>