<div fxFlex="1 1 auto" fxLayout="column">
    <app-loading-view *ngIf="uiState == ViewUiState.loading" fxFlex="1 1 auto" [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
    </app-loading-view>

    <app-error-view *ngIf="uiState == ViewUiState.error" fxFlex="1 1 auto" [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}" [errorText]="errorText" (resetClick)="onClickReset()">
    </app-error-view>

    <div fxLayout="row" fxLayoutAlign="center" *ngIf="uiState == ViewUiState.data" id="myTemp" [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
        <mat-card fxFlex="70%" fxFlex.xl="70%" fxFlex.lg="85%" fxFlex.md="90%" fxFlex.sm="90%" fxFlex.xs="100%" [ngStyle.xs]="{'margin.px': 0, 'padding.px': 0}" [ngStyle.gt-xs]="{'margin.px': 8, 'padding.px': 0}">

            <div fxLayout="row wrap">
                <h2 [ngStyle.xs]="{'padding.px': 8, 'margin.px': 0}" [ngStyle.gt-xs]="{'padding.px': 16, 'margin.px': 0}">
                    <span>Daily Report</span>
                </h2>
                <span class="spacer"></span>
                <div fxLayout="row" fxLayoutAlign="space-around center">
                    <button *ngIf="!mobileView" mat-button (click)="onClickPrint()">
            <mat-icon style="padding: 8px;">print</mat-icon>
            <span style="padding-right: 14px;">Print</span>
          </button>
                    <mat-form-field appearance="standard" style="padding: 8px; width: 150px; margin-bottom: -1.25em">
                        <mat-label>Device</mat-label>
                        <mat-select [value]="selectedMapping" (valueChange)="onClickMappingChange($event)">
                            <mat-option *ngFor="let mapping of mappings; index as i" [value]="mapping">
                                {{ mapping.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="standard" style="padding: 8px; width: 150px; padding-right: 16px; margin-bottom: -1.25em;">
                        <mat-label>Date</mat-label>
                        <input matInput [matDatepicker]="datePicker" [value]="selectedDate" [max]="today" (dateChange)="onClickDateChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                        <mat-datepicker #datePicker disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <mat-error fxLayout="row" fxLayoutAlign="start center" *ngIf="bannerText" class="error-container" style="border-radius: 4px; padding: 0px 16px 0px 16px;">
                <span>{{ bannerText }}</span>
                <span class="spacer"></span>
                <button mat-icon-button color="warn" (click)="bannerText = null"><mat-icon>close</mat-icon></button>
            </mat-error>

            <mat-card-content [ngStyle.xs]="{'padding-top.px': 0, 'padding-right.px': 4, 'padding-bottom.px': 4, 'padding-left.px': 4}" [ngStyle.gt-xs]="{'padding-top.px': 0, 'padding-right.px': 0, 'padding-bottom.px': 16, 'padding-left.px': 0}">
                <div fxLayout="row wrap" [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}" [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex="30%" fxFlex.lt-md="100%" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row wrap">
                            <h4 style="margin: 0px; display: inline;">Total Consumption</h4>
                        </div>
                        <hr>
                        <mat-card-subtitle>
                            {{ selectedDate.clone().subtract(1, 'day') | date: 'EEEE, d MMM y' }} vs {{ selectedDate | date: 'EEEE, d MMM y' }}
                        </mat-card-subtitle>
                        <div fxLayout="row wrap">
                            <div fxLayout="column" fxFlex="50%" fxFlex>
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Net Volume</h3>
                                    <h2>
                                        {{ dayDatum.net_volume * unitDetails.volume| number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </h2>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="50%" fxFlex>
                                <div *ngIf="selectedTarget.dailyNocLimit" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Daily NOC Limit</h3>
                                    <h2>
                                        {{ selectedTarget.dailyNocLimit * unitDetails.volume| number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </h2>
                                </div>
                                <div *ngIf="!selectedTarget.dailyNocLimit" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Previous Net Volume</h3>
                                    <h2>
                                        {{ dayBeforeDatum.net_volume * unitDetails.volume| number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div class="ngx-chart-wrapper" style="padding: 16px 0px;" fxLayout="column">
                            <ngx-charts-linear-gauge *ngIf="selectedTarget.dailyNocLimit" [view]="[300, 150]" [scheme]="comparisonGauseColorScheme" [value]="dayDatum.net_volume * unitDetails.volume" [previousValue]="selectedTarget.dailyNocLimit * unitDetails.volume" [max]="max(selectedTarget.dailyNocLimit * 1.2, dayDatum.net_volume * 1.2) * unitDetails.volume"
                                [units]="''" [valueFormatting]="literValueFormatting">
                            </ngx-charts-linear-gauge>
                            <ngx-charts-linear-gauge *ngIf="!selectedTarget.dailyNocLimit" [view]="[300, 150]" [scheme]="comparisonGauseColorScheme" [value]="dayDatum.net_volume" [previousValue]="dayBeforeDatum.net_volume" [max]="max(dayBeforeDatum.net_volume * 1.2, dayDatum.net_volume * 1.2)"
                                [units]="''" [valueFormatting]="literValueFormatting">
                            </ngx-charts-linear-gauge>
                        </div>
                    </mat-card>
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex="30%" fxFlex.lt-md="100%" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row">
                            <h4 style="margin: 0px; display: inline;">Shift Comparison</h4>
                        </div>
                        <hr>
                        <mat-card-subtitle>
                            {{ selectedDate.clone().subtract(1, 'day') | date: 'EEEE, d MMM y' }} vs {{ selectedDate | date: 'EEEE, d MMM y' }}
                        </mat-card-subtitle>
                        <div fxLayout="row" fxLayoutAlign="space-around center">
                            <div class="ngx-chart-wrapper" style="padding: 16px 0px;" fxLayout="column">
                                <ngx-charts-area-chart [results]="shiftChartCumulativeData" [gradient]="false" [scheme]="shiftChartColorScheme" [showGridLines]="true" [xAxis]="true" [yAxis]="true">
                                </ngx-charts-area-chart>
                            </div>
                        </div>
                    </mat-card>
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex="30%" fxFlex.lt-md="100%" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row">
                            <h4 style="margin: 0px; display: inline;">Day Details</h4>
                        </div>
                        <hr>
                        <mat-card-subtitle>
                            {{ dayDatum.start_time | date: 'EEEE, d MMM y | h:mm a' }} to<br> {{ dayDatum.end_time | date: 'EEEE, d MMM y | h:mm a' }}
                        </mat-card-subtitle>
                        <div fxLayout="row wrap">
                            <div fxLayout="column" fxFlex="50%">
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Working Time</h3>
                                    <p style="font-size: 18px">
                                        {{ dayDatum.getWorkHourString() }}
                                    </p>
                                </div>
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Forward Flow</h3>
                                    <p style="font-size: 18px">
                                        {{ dayDatum.forward_flow * unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.volume }}
                                    </p>
                                </div>
                                <div *ngIf="selectedTarget.showElectricityRate" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Electricity Bill</h3>
                                    <p style="font-size: 18px">
                                        {{ dayDatum.electricity_bill | currency:'INR':'₹ ':'1.0' }}
                                    </p>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="50%">
                                <div style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Flow Rate</h3>
                                    <p style="font-size: 18px">
                                        {{ dayDatum.flowrate * unitDetails.volume/unitDetails.time | number: '1.2-2' }} {{ unitDetails.symbols.flowrate }}
                                    </p>
                                </div>
                                <!-- <div style="padding: 0px 0px 8px 0px;">
                  <h3 style="font-size: 14px; margin: 0px">Reverse Flow</h3>
                  <p style="font-size: 18px">
                    {{ dayDatum.reverse_flow * unitDetails.volume | number: '1.2-2' }} {{ unitDetails.symbols.flowrate }}
                  </p>
                </div>-->
                                <div *ngIf="selectedTarget.showWaterRate" style="padding: 0px 0px 8px 0px;">
                                    <h3 style="font-size: 14px; margin: 0px">Water Bill</h3>
                                    <p style="font-size: 18px">
                                        {{ dayDatum.water_bill | currency:'INR':'₹ ':'1.0' }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>

                <div fxLayout="row" [ngStyle.xs]="{'margin-left.px': 4, 'margin-right.px': 4}" [ngStyle.gt-xs]="{'margin-left.px': 8, 'margin-right.px': 8}">
                    <mat-card class="mat-elevation-z0 outline" style="border-radius: 8px;" fxFlex [ngStyle.xs]="{'margin-bottom.px': 8, 'padding.px': 16}" [ngStyle.gt-xs]="{'margin-top.px': 8, 'margin.px': 8, 'padding.px': 16}">
                        <div fxLayout="row">
                            <h4 style="margin: 0px; display: inline;">Daily Shift-wise Consumption</h4>
                        </div>
                        <hr>
                        <mat-card-subtitle>
                            {{ selectedDate.clone().subtract(1, 'day') | date: 'EEEE, d MMM y' }} to {{ selectedDate | date: 'EEEE, d MMM y' }}
                        </mat-card-subtitle>
                        <div *ngIf="dailyShifts" fxLayout="row wrap" fxLayoutAlign="center center">
                            <div *ngIf="dailyShifts.length > 0" fxLayout="column" fxFlex="33%" fxFlex.lt-md="90%">
                                <ngx-charts-number-card style="max-height: 150px; padding-left: 8px; pointer-events: none;" bandColor=#31C3DF cardColor=#232837 [results]="[dailyShifts[0]]" [valueFormatting]="literValueFormatting">
                                </ngx-charts-number-card>
                            </div>
                            <div *ngIf="dailyShifts.length > 1" fxLayout="column" fxFlex="33%" fxFlex.lt-md="90%">
                                <ngx-charts-number-card style="max-height: 150px; padding-left: 8px; pointer-events: none;" bandColor=#FFD47A cardColor=#232837 [results]="[dailyShifts[1]]" [valueFormatting]="literValueFormatting">
                                </ngx-charts-number-card>
                            </div>
                            <div *ngIf="dailyShifts.length > 2" fxLayout="column" fxFlex="33%" fxFlex.lt-md="90%">
                                <ngx-charts-number-card style="max-height: 150px; padding-left: 8px; pointer-events: none;" bandColor=#DC189C cardColor=#232837 [results]="[dailyShifts[2]]" [valueFormatting]="literValueFormatting">
                                </ngx-charts-number-card>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>