import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalService } from './local.service';

enum Theme {
  kritsnam = "kri",
  staging = "sta",
  development = "dev",
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  isDark$: BehaviorSubject<boolean>;

  constructor(
    private localService: LocalService,
  ) {
    const theme = this.getThemeFromUrl();
    const isDark = this.localService.getDarkThemeState();
    this.isDark$ = new BehaviorSubject<boolean>(isDark);
    this.apply(theme, isDark);
  }

  private getThemeFromUrl() {
    switch(window.location.hostname) {
      case 'localhost': return Theme.development;
      case 'dashboard-staging-kritsnam.web.app': return Theme.staging;
      case 'dashboard-staging-kritsnam.firebaseapp.com': return Theme.staging;
      case 'dashboard-staging.kritsnam.in': return Theme.staging;
      case 'dashboard-staging.kritsnam.com': return Theme.staging;
      case 'dashboard-kritsnam.web.app': return Theme.kritsnam;
      case 'dashboard-kritsnam.firebaseapp.com': return Theme.kritsnam;
      case 'dashboard.kritsnam.in': return Theme.kritsnam;
      case 'dashboard.kritsnam.com': return Theme.kritsnam;
      default: return Theme.kritsnam;
    }
  }

  public toggleTheme() {
    const current = this.localService.getDarkThemeState();
    this.localService.setDarkThemeState(!current);
    const theme = this.getThemeFromUrl();
    const isDark = this.localService.getDarkThemeState();
    this.isDark$.next(isDark);
    this.apply(theme, isDark);
  }

  private apply(theme: Theme, dark: boolean) {
    this.applyThemeClass(theme, dark);
    this.applyTitle(theme);
    this.applyFavicon(theme);
    this.applyLoginBackground(theme);
    this.applyLogo(theme);
    this.applyLogoSquare(theme);
  }

  private applyThemeClass(theme: Theme, dark: boolean) {
    const themePrefix = theme.toString();
    const darkMode = dark ? 'dark': 'light';
    const themeClass = `${themePrefix}-${darkMode}-theme`;
    let body = document.getElementsByTagName('body')[0];
    // Remove existing theme
    body.classList.value.split(' ')
      .filter(c => c.includes('theme'))
      .map(c => body.classList.remove(c));
    // Add new theme
    body.classList.add(themeClass);
  }

  private applyTitle(theme: Theme) {
    const themePrefix = theme.toString();
    const themeVar = `--${themePrefix}-theme-title`;
    const title = this.getCssVariable(themeVar);
    document.title = title;
    this.setCssVariable('--toolbar-title', title);
  }

  private applyFavicon(theme: Theme) {
    const themePrefix = theme.toString();
    const themeVar = `--${themePrefix}-theme-favicon`;
    const favicon = this.getCssVariable(themeVar);
    // https://stackoverflow.com/a/260876
    var link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = favicon;
 }

  private applyLoginBackground(theme: Theme) {
    const themePrefix = theme.toString();
    const themeVar = `--${themePrefix}-theme-background`;
    const background = this.getCssVariable(themeVar);
    this.setCssVariable('--login-background-image', background)
  }

  private applyLogo(theme: Theme) {
    const themePrefix = theme.toString();
    const themeVar = `--${themePrefix}-theme-logo`;
    const logo = this.getCssVariable(themeVar);
    this.setCssVariable('--logo-image', logo)
  }

  private applyLogoSquare(theme: Theme) {
    const themePrefix = theme.toString();
    const themeVar = `--${themePrefix}-theme-logo-square`;
    const logo = this.getCssVariable(themeVar);
    this.setCssVariable('--logo-square-image', logo)
  }

  private getCssVariable(name: string) {
    var style = getComputedStyle(document.body)
    return style.getPropertyValue(name)
  }

  private setCssVariable(name: string, value: any) {
    let body = document.getElementsByTagName('body')[0];
    body.style.setProperty(name, value);
  }
}
