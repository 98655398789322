<div id="kritsnam-clients-page" fxFlex="1 1 auto" fxLayout="column">
  <app-loading-view *ngIf="uiState == ViewUiState.loading" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">
  </app-loading-view>

  <app-error-view *ngIf="uiState == ViewUiState.error" fxFlex="1 1 auto"
  [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}"
  [errorText]="errorText" (resetClick)="onClickReset()">
  </app-error-view>

  <div *ngIf="uiState == ViewUiState.data"
    fxLayout="row" fxLayoutAlign="center" fxFlex="1 1 auto"
    [ngStyle.xs]="{'padding.px': 0}" [ngStyle.gt-xs]="{'padding.px': 32}">

    <div *ngIf="users.length === 0"
    fxLayout="column" fxLayoutAlign="center center" fxFlex="1 1 auto">
      <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="none center"
      class="animate__animated animate__pulse">
        <span class="mat-body-strong" style="text-align: center;">
          No users found.
        </span>
      </div>
    </div>

    <ag-grid-angular *ngIf="users.length !== 0"
    [ngStyle]="{'visibility': showGrid ? 'visible' : 'hidden' }"
    id="grid"
    #agGrid
    style="width: 100%;"
    class="ag-theme-material ag-grid-height-fix"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [context]="agGridContext"
    [suppressCellSelection] ="true"
    [frameworkComponents]="getComponents()"
    [defaultColDef]="{
      resizable: true,
      sortable: true,
      filter: true,
      filterParams: { newRowsAction: 'keep'}
    }"
    domLayout='autoHeight'
    (rowClicked)='onRowClicked($event)'
    (gridReady)="gridReady($event)"
    (firstDataRendered)="onFirstDataRendered($event)"
    (sortChanged)="onSortChanged($event)"
    (filterChanged)="onFilterChanged($event)">
    </ag-grid-angular>
  </div>
</div>
